/* prettier-ignore */
export default function () {
    const getMedia = (imageName: string) => {
        const imagePath = process.env.VUE_APP_IMAGES_URL
        if(imageName && imageName !== 'No Image'){
            return `${imagePath}/${imageName}`
        }else{
            return require('@/assets/images/noImage.jpg')
        }
    }
    return {
        getMedia
    }
  }
