
import { defineComponent, PropType } from 'vue'
import Filters from '@/components/organisms/Filters.vue'
import { AggregationsType } from '@/types/search'
import { useRoute, useRouter } from 'vue-router'
import userQueryArrayResolver from '@/utils/queryFunctions'
export default defineComponent({
  components: {
    Filters
  },
  props: {
    aggregations: {
      type: Object as PropType<AggregationsType>,
      required: true
    },
    withoutFile: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { queryArrayResolver } = userQueryArrayResolver()
    const route = useRoute()
    const router = useRouter()
    const checkboxChange = (
      event: {
        isChecked: boolean
        item: { id: number; name: string; value: string; isChecked: boolean; doc_count: number; key: string }
      },
      key: string
    ) => {
      let newObj: any = queryArrayResolver(route.query)
      const filterObj = newObj[key]
      const filterArr = filterObj ? [...filterObj] : []
      const filterKeyIndex = filterArr.findIndex((element) => element === event.item.key)
      if (filterKeyIndex === -1) {
        filterArr.push(event.item.key)
      } else {
        filterArr.splice(filterKeyIndex, 1)
      }
      newObj = Object.assign({}, newObj, { [key]: [...filterArr] })
      newObj = Object.assign({}, newObj, { offset: 0 })
      if (props.withoutFile) {
        router.push({ name: 'SearchWithoutFile', query: newObj }).then(() => {
          emit('getSearchResults')
        })
      } else {
        router
          .push({ name: 'MainSearch', query: newObj, params: { id: route.params.id, name: route.params.name } })
          .then(() => {
            emit('getSearchResults')
          })
      }
      // }
    }
    return { checkboxChange }
  }
})
