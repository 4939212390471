import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!

  return (_ctx.aggregations)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aggregations, (value, key) => {
          return (_openBlock(), _createElementBlock("div", { key: key }, [
            _createVNode(_component_Filters, {
              onCheckboxChange: ($event: any) => (_ctx.checkboxChange($event, key)),
              items: value.buckets,
              label: key
            }, null, 8, ["onCheckboxChange", "items", "label"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}