/* prettier-ignore */
export default function () {
    // const numFormatter = (num: number) => {
    //     if (num < 1000) {
    //         return Math.trunc(num) // if value < 1000, convert 99.00 to 99
    //       } else if (num > 999 && num < 1000000) {
    //         return (num / 1000).toFixed(3) // convert 1000 to 1.000
    //       }
    //       return (num / 1000).toFixed(3) // convert 1000001 to 1000.001
    // }
    function numFormatter(num: number) {
        return num.toLocaleString('nl-NL')
    }
    return {
      numFormatter
    }
  }
