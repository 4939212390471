import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f8fa065"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-wrap" }
const _hoisted_2 = { class: "checkbox" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        id: _ctx.label,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isChecked) = $event))
      }, null, 40, _hoisted_3), [
        [_vModelCheckbox, _ctx.isChecked]
      ]),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.label
          }, [
            _createElementVNode("span", null, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(_ctx.numFormatter(_ctx.item.doc_count)) + ")", 1)
            ])
          ], 8, _hoisted_4))
        : _createCommentVNode("", true)
    ])
  ]))
}