
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Modal',
  props: {
    type: {
      type: String,
      default: ''
    }
  }
})
