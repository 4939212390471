
import { defineComponent } from 'vue'
import type { searchType } from '@/types/search'
import useNumFormatter from '@/utils/filter'
import useGetMedia from '@/utils/getMedia'
export default defineComponent({
  props: {
    item: {
      type: Object as () => searchType,
      required: true
    }
  },
  setup() {
    const { numFormatter } = useNumFormatter()
    const { getMedia } = useGetMedia()
    return {
      numFormatter,
      getMedia
    }
  }
})
