
import { defineComponent, PropType } from 'vue'
import Checkbox from '@/components/molecules/Checkbox.vue'
import { SingleFacet, FacetTranslation } from '@/types/search'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'Category',
  props: {
    items: {
      type: Array as PropType<SingleFacet[]>,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    Checkbox
  },
  setup(props, { emit }) {
    const route = useRoute()

    function onClick(event: {
      isChecked: boolean
      item: { id: number; name: string; value: string; isChecked: boolean; doc_count: number }
    }) {
      emit('checkboxChange', event)
    }
    const checkSelected = (event: { key: string; doc_count: number }) => {
      let filterObj: any = route.query[props.label]
      if (typeof filterObj === 'string') {
        filterObj = filterObj.split(' ,')
      }
      const filterArr = filterObj ? [...filterObj] : []
      const filterKeyIndex = filterArr.findIndex((element) => element === event.key)
      if (filterKeyIndex === -1) {
        return false
      } else {
        return true
      }
    }

    const facetList: FacetTranslation[] = [
      {
        eng: 'topcategory',
        dutch: 'Topcategorie'
      },
      {
        eng: 'maincategory',
        dutch: 'Hoofdcategorie'
      },
      {
        eng: 'subcategory',
        dutch: 'Subcategorie'
      },
      {
        eng: 'manufacturer',
        dutch: 'Merk'
      },
      {
        eng: 'model_type',
        dutch: 'Type'
      },
      {
        eng: 'country',
        dutch: 'Land'
      },
      {
        eng: 'source',
        dutch: 'Bron'
      }
    ]
    const translateFacetName = (facet: string) => {
      return facetList.find((val: FacetTranslation) => val.eng === facet)?.dutch
    }

    return { onClick, checkSelected, translateFacetName }
  }
})
