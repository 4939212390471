
import { defineComponent, ref, computed, WritableComputedRef, watch } from 'vue'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import SearchBar from '@/components/molecules/SearchBar.vue'
import InputField from '@/components/atom/InputField.vue'
import PerPageItemsComp from '@/components/atom/PerPageItemsComp.vue'
import List from '@/components/atom/List.vue'
import FiltersWrapper from '@/components/organisms/FiltersWrapper.vue'
import { useRoute } from 'vue-router'
import MainApi from '@/api/MainApi'
import qs from 'qs'
import router from '@/router'
import Pagination from '@/components/atom/Pagination.vue'
import userQueryArrayResolver from '@/utils/queryFunctions'
import useSlugify from '@/utils/slugify'
import axios from 'axios'
import datepicker from 'vue3-datepicker'
import type { searchType } from '@/types/search'
import CustomButton from '@/components/atom/CustomButton.vue'
import _ from 'lodash'
import useGetMedia from '@/utils/getMedia'
import useNumFormatter from '@/utils/filter'

export default defineComponent({
  name: 'MainSearch',
  props: {
    withoutFile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    datepicker,
    SearchBar,
    InputField,
    PerPageItemsComp,
    List,
    FiltersWrapper,
    Pagination,
    CustomButton
  },
  setup(props) {
    const { numFormatter } = useNumFormatter()
    const heading = [
      {
        id: 1,
        type: 'Foto'
      },
      {
        id: 2,
        type: 'Merk & Type'
      },
      {
        id: 3,
        type: 'Bouwjaar'
      },
      {
        id: 4,
        type: 'Kavel titel en omschrijving'
      },
      {
        id: 5,
        type: 'Eindbod'
      },
      {
        id: 6,
        type: 'Biedingen'
      },
      {
        id: 7,
        type: 'Einddatum'
      },
      {
        id: 8,
        type: 'Bron'
      },
      {
        id: 9,
        type: 'Land'
      },
      {
        id: 10,
        type: 'Actie'
      }
    ]

    const sortColumnNames = [
      {
        id: 10,
        type: 'reference'
      }
    ]

    const { slugify } = useSlugify()
    const isMenu = ref(false)
    const route = useRoute()
    const searchResult = ref([])
    const searchResultPdf = ref([])
    const defaultPageSize = 10
    const { queryArrayResolver } = userQueryArrayResolver()
    let dossierName = route.params && route.params.name ? route.params.name.toString() : ''
    dossierName = dossierName ? dossierName.split('-').join(' ') : ''
    const searchKeyword = ref(route.query.keyword ? route.query.keyword : '')
    const perPageItems = ref(route.query.pageSize ? +route.query.pageSize : defaultPageSize)
    const filterParams = ref({
      yearFrom: route.query.yearFrom ? route.query.yearFrom : '',
      yearTo: route.query.yearTo ? route.query.yearTo : '',
      eindbodFrom: route.query.eindbodFrom ? route.query.eindbodFrom : '',
      eindbodTo: route.query.eindbodTo ? route.query.eindbodTo : '',
      dateFrom: route.query.dateFrom ? route.query.dateFrom : null,
      dateTo: route.query.dateTo ? route.query.dateTo : null
    })
    const dateFrom = route.query.dateFrom ? ref(new Date(route.query.dateFrom.toString())) : ref()
    const dateTo = route.query.dateTo ? ref(new Date(route.query.dateTo.toString())) : ref()
    const aggregations = ref({})
    /* eslint-enable */
    const getHeader = computed(() => {
      const customHeader: Array<{ id: number; type: string }> = [...heading]
      if (props.withoutFile) {
        customHeader.splice(customHeader.length - 1, 1)
        return customHeader
      } else if (route.query.dossier) {
        customHeader[9] = { id: 10, type: 'Taxatie item nr.' }
        customHeader[10] = { id: 11, type: 'Opmerking' }
        customHeader[11] = { id: 12, type: 'Actie' }
        return customHeader
      }
      return heading
    })
    const sort = ref({
      columnId: route.query.sortColumn
        ? getHeader.value[getHeader.value.findIndex((val) => val.type === route.query.sortColumn)].id
        : 0,
      order: route.query.sortOrder ? route.query.sortOrder : ''
    })
    watch(() => dateFrom.value, changeDateFrom)
    watch(() => dateTo.value, changeDateTo)
    function changeDateFrom() {
      filterParams.value.dateFrom = dateFrom.value
        ? `${dateFrom.value.getFullYear()}-${appendLeadingZeroes(dateFrom.value.getMonth() + 1)}-${appendLeadingZeroes(
            dateFrom.value.getDate()
          )}`
        : ''
      updateFilter()
    }
    function changeDateTo() {
      filterParams.value.dateTo = dateTo.value
        ? `${dateTo.value.getFullYear()}-${appendLeadingZeroes(dateTo.value.getMonth() + 1)}-${appendLeadingZeroes(
            dateTo.value.getDate()
          )}`
        : ''
      updateFilter()
    }
    function appendLeadingZeroes(n: number) {
      if (n <= 9) {
        return `0${n}`
      }
      return n
    }
    /* eslint-disable */
    const getResults: WritableComputedRef<Array<searchType>> = computed({
      get(): Array<searchType> {
        let item: Array<searchType> = []
        if (searchResult.value) {
          item = searchResult.value.filter((subitem: { _source: searchType }) => subitem._source.hasOwnProperty('id'))
        }
        return item
      },
      set(): void {}
    })
    function openCalender() {
      // console.log('openCalender')
    }
    const totalRecord = ref(10)

    const totalPages = computed(() => {
      return Math.ceil(totalRecord.value / perPageItems.value)
    })

    const currentPage = computed(() => {
      return route.query.offset ? +route.query.offset / perPageItems.value + 1 : 1
    })
    const updateFilter = async () => {
      let query = route.query
      query.offset = '0'
      query = Object.assign({}, query, filterParams.value)
      Object.keys(query).forEach((key) => {
        if (query[key] === null || query[key] === '') {
          delete query[key]
        }
      })
      applyQuery(query)
    }
    const getSearchResults = async () => {
      const query = route.query ? { ...route.query } : {}
      delete query.dossier
      let res
      if (!route.query.dossier) {
        res = await MainApi.search(qs.stringify(queryArrayResolver(query)))
        if (res && res.aggs) {
          aggregations.value = sortAggs(res.aggs)
        }
      } else {
        const timestamp = new Date().getTime();
        res = await MainApi.getDossier(qs.stringify({ projectcode: route.params.id, ...query, timestamp }));
      }
      if (res) {
        totalRecord.value = res.count
        searchResult.value = res.result
        searchResultPdf.value = res.resultPdf
      }
    }
    getSearchResults()

    /// //Search Functions

    const perPageItemsChange = async (value: number) => {
      let query = route.query
      query = Object.assign({}, query, { pageSize: value, offset: 0 })
      applyQuery(query)
    }

    const updatePagination = async (value: number) => {
      let query = route.query
      const offset = (value - 1) * perPageItems.value
      query = Object.assign({}, query, { offset: offset })
      applyQuery(query)
    }
    const onSearch = async (value: string) => {
      let query = route.query
      if (query.keyword !== value) {
        query = Object.assign({}, query, { keyword: value, offset: 0 })
        if (value === '') {
          delete query.keyword
        }
        applyQuery(query)
      }
    }
    const applyQuery = async (query: any) => {
      if (props.withoutFile) {
        router.push({ name: 'SearchWithoutFile', query }).then(() => {
          getSearchResults()
        })
      } else {
        router
          .push({ name: 'MainSearch', query, params: { id: route.params.id, name: route.params.name } })
          .then(() => {
            getSearchResults()
          })
      }
    }
    const clearAllQuery = () => {
      // for(const prop in filterParams.value ){
      //   filterParams.value[prop] = ''
      // }
      sort.value = {
        columnId: 0,
        order: ''
      }
      filterParams.value.yearFrom = ''
      filterParams.value.yearTo = ''
      filterParams.value.eindbodFrom = ''
      filterParams.value.eindbodTo = ''
      if(dateFrom.value || dateTo.value){
        // to prevent from calling watchers
        setTimeout(() => {
            if (dateFrom.value) {
              dateFrom.value = null
              filterParams.value.dateFrom = null
            }
            if (dateTo.value) {
                dateTo.value = null
                filterParams.value.dateTo = null
            }
          }, 0.0)
      }
      applyQuery({})
    }
    const { getMedia } = useGetMedia()
    const getBase64FromUrl = async (imageName: string) => {
      return new Promise((resolve, reject) => {
        axios
          .get(getMedia(imageName), {
            responseType: 'blob'
          })
          .then((res) => {
            const reader = new FileReader()
            reader.readAsDataURL(res.data)
            reader.onloadend = () => {
              const base64data = reader.result
              resolve(base64data)
            }
          })
          .catch((err) => reject(err))
      })
    }
    const download = async () => {
      const pdfArray = []
      interface ExtendedSearchType extends searchType {
        imageurl: string
        imageid: string
        base64Image: string | any
      }
      const customResult: Array<{ _source: ExtendedSearchType }> = searchResultPdf.value
      for (let j = 0; j < customResult.length; j++) {
        let imageURL = ''
        if (customResult[j]._source.imageurl) {
          imageURL = customResult[j]._source.imageurl // pass only image id
        }
        customResult[j]._source.base64Image = await getBase64FromUrl(imageURL)
        const data: ExtendedSearchType = customResult[j]._source
        pdfArray[j] = [
          '',
          `Merk:\n${data.manufacturer ?? 'NA'}\n\nType:\n${data.model_type ?? 'NA'}`,
          data.yearbuilt ?? 'NA',
          `${data.title}\n\n${data.description}`,
          data.currentbid ? numFormatter(parseInt(data.currentbid)) : 'NA',
          data.numberofbids ?? 'NA',
          data.closedate ? data.closedate.split(' ')[0] : 'NA',
          data.source ?? 'NA',
          data.country ?? 'NA',
          data.reference ?? 'NA',
          data.comment ?? 'NA'
        ]
      }
      const pdfHeading: string[] = []
      getHeader.value.forEach((item: { id: number; type: string }) => {
        if(item.type === 'Taxatie item nr.' ){
          item.type = 'Taxatie nr.'
        }
        pdfHeading.push(item.type)
      })
      pdfHeading.splice(getHeader.value.length - 1, 1)
      generatePDF(pdfHeading, pdfArray, customResult)
    }
    const generatePDF = async (
      heading: string[],
      array: string[][],
      results: Array<{ _source: { base64Image: string } }>
    ) => {
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      const doc = new jsPDF({
        orientation: 'landscape'
      })
      autoTable(doc, {
        margin: {
          top: 15.5
        },
        head: [heading],
        body: array,
        theme: 'plain',
        headStyles: {
          fontStyle: 'bold',
          fillColor: '#8ABEE4',
          valign: 'middle'
        },
        rowPageBreak: 'avoid',
        styles: {
          minCellWidth: 24,
          fontSize: 8,
          overflow: 'linebreak',
          halign: 'left'
        },
        bodyStyles: {
          minCellHeight: 20
        },
        columnStyles: {
          0: { minCellWidth: 30, cellPadding: { bottom: 23 } },
          2: { cellWidth: 16 },
          3: { halign: 'left', minCellWidth: 60 },
          4: { cellWidth: 15 },
          5: { cellWidth: 17 },
          8: { cellWidth: 11 },
          9: { cellWidth: 19 },
          10: { cellWidth: 30 }
        },
        // eslint-disable-next-line prettier/prettier
        didDrawCell: function (HookData) {
          if (HookData.column.index === 0 && HookData.cell.section === 'body') {
            doc.addImage(
              results[HookData.row.index]._source.base64Image,
              'JPEG',
              HookData.cell.x + 2,
              HookData.cell.y + 2,
              25,
              20
            )
          }
        },
        didDrawPage: function () {
          doc.setFontSize(15)
          doc.text(`${dossierName} - ${route.params.id}`, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' })
        }
      })
      doc.save(`${slugify(dossierName)}-${route.params.id}.pdf`)
    }

    const sortRecords = (columnId: number, sortOrder: string) => {
      let query = route.query
      let columnName: string | undefined = ''
      if(sortOrder) { //  send col. name to server if order isnt empty
        const callback = ({id}: {id: number, type: string }) => id == columnId
        columnName = sortColumnNames.find(callback)?.type || getHeader.value.find(callback)?.type || ''
      }
      query = { ...query, sortColumn: columnName, sortOrder: sortOrder }
      applyQuery(query)
    }
    const redirectTo = _.debounce((pageName: string) => {
      let routeOptions = {}
      switch (pageName) {
        case 'dossier_inzien':
          routeOptions = {
            name: 'MainSearch',
            query: { dossier: 'true' },
            params: { id: route.params.id, name: route.params.name },
            replace: true
          }
          break
        case 'objecten_toevoegen':
          routeOptions = {
            name: 'MainSearch',
            params: { id: route.params.id, name: route.params.name },
            replace: true
          }
          break
      }
      router.push(routeOptions).then(() => {
        getSearchResults()
      })
      sort.value.columnId = 0
      sort.value.order = ''
      perPageItems.value = defaultPageSize
    }, 300)

    // for changing Order of Keys
    const sortAggs = (aggs: any) => {
      return {
        topcategory: aggs.topcategory,
        maincategory: aggs.maincategory,
        subcategory: aggs.subcategory,
        manufacturer: aggs.manufacturer,
        model_type: aggs.model_type,
        country: aggs.country,
        source: aggs.source
      }
    }

    return {
      isMenu,
      getHeader,
      openCalender,
      perPageItems,
      heading,
      searchResult,
      perPageItemsChange,
      totalPages,
      currentPage,
      updatePagination,
      dossierName,
      searchKeyword,
      onSearch,
      applyQuery,
      filterParams,
      dateFrom,
      dateTo,
      updateFilter,
      aggregations,
      getSearchResults,
      download,
      getResults,
      clearAllQuery,
      sortRecords,
      sort,
      redirectTo
    }
  }
})
