import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-310f50c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "subcategory-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.translateFacetName(_ctx.label)), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.key
        }, [
          _createVNode(_component_Checkbox, {
            onOnClick: _ctx.onClick,
            item: { key: item.key, doc_count: item.doc_count, isChecked: _ctx.checkSelected(item) },
            label: item.key,
            facet: _ctx.label
          }, null, 8, ["onOnClick", "item", "label", "facet"])
        ]))
      }), 128))
    ])
  ]))
}