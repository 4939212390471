
import { defineComponent, ref, watch } from 'vue'
import useNumFormatter from '@/utils/filter'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Category',
  emits: ['onClick'],
  props: {
    label: {
      type: String,
      default: ''
    },
    item: {
      // eslint-disable-next-line camelcase
      type: Object as () => { id: number; name: string; value: string; isChecked: boolean; doc_count: number },
      required: true
    },
    facet: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { numFormatter } = useNumFormatter()
    const route = useRoute()
    const isChecked = ref(props.item.isChecked)
    const onClick = () => {
      emit('onClick', { isChecked: isChecked.value, item: props.item })
    }
    watch(
      () => route.query,
      (qp: any) => {
        if (!qp[props.facet]) {
          isChecked.value = false
        }
      }
    )

    return { isChecked, onClick, numFormatter }
  }
})
