import { AxiosError, AxiosRequestConfig } from 'axios'
import { BaseApi } from './base'
import authentication from '../authentication'
import router from '@/router'
class AuthApi extends BaseApi {
  public constructor() {
    super(process.env.VUE_APP_API_URL)
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
    this.instance.interceptors.request.use(this._handleRequest)
  }

  public search = (query: string) => {
    return this.instance.get(`/search?${query}`)
  }

  public getDossierCode = (id: string) => {
    return this.instance.get(`/ntab_reference/_doc/${id}`)
  }

  public getItems = (id: string) => {
    return this.instance.get(`/ntab_dossier/_doc/${id}`)
  }

  public creatDossier = (id: string, payload: { object_id: Array<string> }) => {
    return this.instance.post(`/ntab_dossier_create/_doc/${id}`, payload)
  }

  public updateDossier = (id: string, payload: { object_id: Array<string> }) => {
    return this.instance.post(`/ntab_dossier/_doc/${id}`, payload)
  }

  public getDossier = (query: string) => {
    return this.instance.get(`dossier/items?${query}`)
  }

  public updateReference = (payload: { projectcode: string }, id: string) => {
    return this.instance.post(`/ntab_reference/_update/${id}`, payload)
  }

  public updateComment = (payload: { reference: string; comment: string }, id: string) => {
    return this.instance.post(`/ntab_comment/_update/${id}`, payload)
  }

  public removeDossier = (payload: { projectcode: string }, id: string) => {
    return this.instance.post(`remove_projectcode/${id}`, payload)
  }

  protected _handleError = (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (router.currentRoute.value.name !== 'Home') {
        router.push({ name: 'Home' })
      }
    }
    Promise.reject(error)
  }

  protected _handleRequest = (config: AxiosRequestConfig) => {
    authentication.acquireToken().then((token: string) => {
      config.headers.Authorization = `Bearer ${token}`
    })
    return config
  }
}

const authApi: AuthApi = new AuthApi()

export default authApi
